<template>
  <div class="relative py-8 pb-0 sm:pb-0" :class="bgCol">
    <div :class="fillColBg" class="absolute top-0 -mt-8 w-full sm:-mt-24">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1440 126"
        style="enable-background: new 0 0 1440 126"
        xml:space="preserve"
      >
        <path
          class="svgFill"
          d="M0,0h48c48,0,144,0,240,21.3C384,43,480,85,576,96s192-11,288-10.7c96-0.3,192,21.7,288,26.7s192-5,240-10.7
	l48-5.3v28h-48c-48,0-144,0-240,0s-192,0-288,0s-192,0-288,0s-192,0-288,0s-192,0-240,0H0V0z"
        />
      </svg>
    </div>
    <h3 class="mb-8 p-8 text-center text-2xl font-semibold xl:text-3xl">
      How it works...
    </h3>
    <div class="container relative mx-auto">
      <div
        class="flex w-full flex-col items-start px-6 sm:flex-row sm:justify-between sm:px-0"
      >
        <div
          class="z-10 mb-8 flex w-full flex-col justify-center text-center sm:mb-0 sm:w-64"
        >
          <div
            class="color-transition relative mx-auto flex h-32 w-32 items-center justify-center rounded-full sm:h-40 sm:w-40"
            :class="[fillCol, bgCol]"
          >
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 370.22 370.22"
              class="h-full w-full"
            >
              <path
                class="svgFill"
                d="M346.82,197.58c-.86-10.83-4-21.18-7.4-31.43-3.25-9.92-8.05-19-15.56-26.51-2-1.95-4-3.82-6.75-4.39a12.8,12.8,0,0,1-6.95-4.4,140.12,140.12,0,0,0-34.71-27.31c-9.12-5.19-19.1-8.35-28.74-12.3a202,202,0,0,0-94-14.77c-10.58.84-21.12,2.15-31.25,5.62-38.14,13-64.71,39.26-83.43,74-7.34,13.62-12.26,28.46-18.62,42.6a53.45,53.45,0,0,0-4.52,24.4c.21,4.9,1.18,9.72,1.67,14.58.77,7.66.77,15.44,2.08,23,2.44,14.14,9.21,26.48,17.8,37.77,6.06,7.95,13.78,14.42,20.45,21.83,13.27,14.72,30.54,23.09,48.29,30.63,7.47,3.17,15.66,2.72,24,3.16,1.58.19,3.57.35,5.54.67,9.56,1.58,18.94.7,28.29-1.7,11.52-3,22.77-6.82,34.23-10,24.73-6.76,46.74-19.11,68-33,4-2.63,6.91-6.69,10.94-9.36,7.79-5.16,15.57-10.33,23.38-15.44,12.36-8.09,23.36-17.34,32.21-29.56C344.67,238.05,348.5,218.64,346.82,197.58Z"
              />
              <path
                class="cls-2"
                d="M301.82,198.44c-1.83-11.39-2.76-23.24-4-34.8-3.58-12.88-.14-71.81-12.16-73.26-43.17-17.26-84.17-42-128-58.89-11.14-4.95-21.88-10.88-33-15.75-3.94-2.6-4.1.48-7.46,1.46-9.9,1.68-12.87,11.6-18.95,18.13C93.07,43.79,113.67,47.41,118.84,52c10,21.75,13.41,48.76,25.83,70.78C118.4,150,87.53,177.57,59.34,204.38c-5.94,10.07.54,22.14-1.6,33.4.42,8.67,22.67,20.92,30.93,24.07,33.57,15.78,64.44,37.28,99.57,49.9,16.2,4.53,29.36,19,42.43,1.32,10.76-15.7,84.44-63.78,76.05-76.62C306.83,223.74,312.71,208.25,301.82,198.44ZM110.48,38.18c4.53-3.64,3.47-10.33,9.08-12.8,10.75,5.42,21.83,11,32.7,16.59,41.11,18.75,81.37,37.67,122.11,57.38L263.65,109c-10.33-5.5-22.13-9.77-32.87-14.7C190.25,76.38,151.22,55.63,110.48,38.18Zm24.11,22.38c40,18.58,79.24,38.77,120.47,54.57,13.43,6.85,13.68.67,24.71-7.66,6.59,28.37,3.94,57.21,11.48,86.22-41.63-31.38-90-51.92-137.73-72C144.35,103.34,140.82,80.54,134.59,60.56Zm79,249.51a785.6,785.6,0,0,1-98.59-47C100.38,254.47,83.9,250,69.66,240.5c-3.74-6.49-1.35-16.23-2.55-23.3,42.47,23.92,83,51.51,129.48,67.42C216.35,291.3,213.88,289.5,213.61,310.07Zm-45.54-48.79c-33.42-15.25-63.4-36.57-96.43-52.62,16.63-14.92,32.85-31.83,49.09-46.85,5.23,6.1,160.39,99.77,133.35,65.75,14.83-12.4,34.88-18.44,8.6-33.42-25-17.81-29-4.66-42.13,16.66l-41.44-23.09c18.33-16.78,17-17.69,39.85-7.1-16.09,18.58-1.64,21.85,8.09,3.59,9.24-13.3-16.62-15.13-24.15-20.72C191.8,158.23,180.37,177,174,184.53L134.65,164c22.49-14.95,15.77-18.39,41.77-5.23-4.62,5.57-14.74,12.25-7.44,19.5,28.67-19.94,15.33-30.05-11.42-38.9-11.48-3.25-23.1,25.23-32.87,18.81,6.58-4.46,24.31-31.54,30.37-25.77,48.33,20.18,90.69,50.55,137.63,73.65-22.42,27.9-51,51.07-71.09,80.78C205.32,276.05,185.94,270.26,168.07,261.28ZM225,213c19.6-19.16,16.07-23,40.5-5-5.95,5.25-11,11-12.92,19ZM269,264.27c-15.74,13-31.73,25.58-44.64,41.63-.51-5.87-1-11.45-1.41-16.29,10.12-4.85,17.48-13.69,25.74-21,16.17-16,31.7-32.56,47.87-48.55,1,6.58-1.87,13,1.17,19.15C288.2,247.53,278.74,256.05,269,264.27Z"
              />
              <path
                class="cls-2"
                d="M153.36,204.89c-40.74,35.87-17.83,28.47,14.14,51.79,11.3-.47,13.94-15.45,20.18-22.94C197,216.09,167,202.86,153.36,204.89ZM167.81,246c-6.46-9-16-12.47-25.34-17.34l14.72-19.3C168.63,231.46,189.91,213.91,167.81,246Z"
              />
              <path
                class="cls-2"
                d="M207.52,229c-10.36,1-26.88,21.86-26.47,31.24,1.21,3,3.83,3.64,6.69,3.73,9.1,2.16,15.28,10.89,25.12,12.68,13.57,6.24,17.17-15.49,24.6-23C247,239.31,217.88,231.2,207.52,229Zm9.5,38.23c-9.14-3.1-14.2-9.08-22.35-13.87l9-14.33,24.15,10.69Z"
              />
              <path
                class="cls-2"
                d="M117.06,178.41c-12.85,3.81-19.43,15.73-27.22,25.59-5.75,10.3,20.63,21,28.57,21.44,11.71,1,15.42-16.91,22.63-24.14C144.67,190.73,125,182.16,117.06,178.41Zm-17.62,26.7,11.88-16.2,19.61,11.15C118.86,222.41,118.52,217.61,99.44,205.11Z"
              />
              <path
                class="cls-2"
                d="M238.92,126c3-.28,4.85-1.39,5.09-3.48-.15-6.57-9.84-4.93-14.16-7.54-26.25-8.75-50.5-22.2-76.32-32-1.62-.6-4.26-2.19-5.23.44,1.67,5.45,19,10.91,24.7,14.77C194.94,107.46,215.91,119.67,238.92,126Z"
              />
              <path
                class="cls-2"
                d="M241.16,145.54c-20.89-5.66-38.07-19.85-58.84-25.92-27.27-3.88,56.22,32.11,59.64,31.78l3.08-2C243.84,148,243.22,145.81,241.16,145.54Z"
              />
            </svg>
          </div>
          <h2 class="mb-2 mt-6 text-lg font-semibold">Get a Taster Box</h2>
          <div class="mx-auto">Try all our most popular flavours</div>
        </div>
        <div
          class="z-10 mb-8 flex w-full flex-col justify-center text-center sm:mb-0 sm:w-64"
        >
          <div
            class="color-transition relative mx-auto flex h-32 w-32 items-center justify-center rounded-full sm:h-40 sm:w-40"
            :class="[fillCol, bgCol]"
          >
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 370.22 370.22"
              class="h-full w-full"
            >
              <path
                class="svgFill"
                d="M292.69,132.65c-1.94-9.79-5.49-19.15-8.51-28.63A33.8,33.8,0,0,0,278.89,94c-2.84-3.77-5.82-7.43-8.74-11.14-.84-1-1.24-2.4-2.52-3h0l-.62-.89h0c-.19-2.19-1.82-3.56-3-5.21h0a28.86,28.86,0,0,0-2.77-3.84c-2.35-2.39-4.2-5.37-7.46-6.77h0c-5.93-4.18-12.11-8-17.63-12.77a57.29,57.29,0,0,0-19.37-10.76c-3.6-1.21-7.14-2.61-10.7-3.93h0a3.37,3.37,0,0,0-.72-.65c-8.15-3.77-16.55-6.62-25.62-7.06h0c-10-2.8-19.94-5.42-30.45-4.57a24.06,24.06,0,0,0-10.31,0h0l-2.46.05h0c-3.34.39-6.75.44-9.89,1.85a1.36,1.36,0,0,0-.34.14,6.69,6.69,0,0,0-3,1.07c-3.3,1.52-6.09,4-9.66,5a18.78,18.78,0,0,0-7.56,4.12,38.3,38.3,0,0,0-6.09,6c-6.47,7.83-11.61,16.57-17,25.11-2.84,4.48-5.61,9-7.32,14.11L74.5,83.77h0c-2.2,3.85-3.29,8.11-4.47,12.31-2.73,9.74-3.61,19.84-5.62,29.73-1.1,5.44-2.07,10.9-3.1,16.35a.63.63,0,0,0-.11.45c-2.16,8.86-3.48,17.87-4.88,26.86-.85,5.49-1.37,11-2,16.54-1.09,1.79-.27,3.74-.5,5.61h0c-1,1.43-.3,3-.52,4.57h0c0,.85,0,1.69,0,2.54-1,2-.26,4.07-.48,6.1h0l0,7.1h0l-.49,0V222.1l.49,0h0l0,4.55c.19,1.53-.49,3.14.47,4.58h0l0,2.53c.16,1.18-.45,2.48.47,3.56h0l0,2c.13,1-.41,2.15.48,3.06h0l0,1.51c.1.86-.33,1.82.48,2.55h0l0,1.52c.1.86-.33,1.82.48,2.55h0a78.06,78.06,0,0,0,3,14.75h0a114.08,114.08,0,0,0,8.2,21.82h0c1.41,2.88,3,5.7,4.19,8.66,5.79,14.11,15,25.64,26.45,35.52a1.63,1.63,0,0,0,1.36.53h0c2.14,1.91,4.29,3.81,6.43,5.73,5.73,5.15,12,9.37,19.6,11.22,9.22,2.24,18.6,2.32,28,1.72a23.57,23.57,0,0,0,10.25-3.47c5.74-3.31,9.63-8.39,13.36-13.66,2.36-2.09,3.46-5,5.13-7.58l4.13-6h0a39.8,39.8,0,0,0,5.53-8.19h0a163.27,163.27,0,0,0,8.58-15.83c.37-.84,1.09-1.53,1-2.54,3.3-5.05,7.78-9.13,11.4-13.92,4.53-6,8.48-12.44,13.43-18.12,1-.24,1.47-1.11,2.11-1.76a247,247,0,0,1,19.54-18c6.88-5.63,13.38-11.64,18.49-19,3.67-5.26,7.31-10.54,11.43-15.46,10.83-12.95,15.8-28.09,16.56-44.76.19-4.14-.16-8.31.16-12.44A63.06,63.06,0,0,0,292.69,132.65Z"
              />
              <path
                class="cls-2"
                d="M178,86c-7.66-10.11-37.91-25.06-42.66-6.69-5.18,7.33-11.11,14-10.47,23.14C127.32,151.87,203.65,123.34,178,86Zm-41.28,14c.43-27.72,40-10.35,32.05,5C161.55,121.34,135.51,119.77,136.69,100Z"
              />
              <path
                class="cls-2"
                d="M133.7,254.22c-1.19,2.43-9,4.46-4.86,7.08-19.65,51.85,56.82,60.29,51.33,6.92C177.27,248.35,146.9,238.2,133.7,254.22Zm16.89,38.47c-16.39-1.68-18.89-33.47-1.71-34.48C175.1,256.81,173.18,292.76,150.59,292.69Z"
              />
              <path
                class="cls-2"
                d="M317.55,258.08c-2.76-7.25-12.11-3-17.92-3.11-36.63,3.92-73.23,7.25-109.83,11.62.3,4.45.57,8.57.86,12.81C206.6,274.42,326.08,274.94,317.55,258.08Z"
              />
              <path
                class="cls-2"
                d="M219.43,174.89C217.9,199.53,248,205.75,263.5,192,297.59,157.48,221.65,126.45,219.43,174.89Zm23.76,11.89c-14.44.74-16-18.32-6-25.54C257.57,154.3,269.75,185.24,243.19,186.78Z"
              />
              <path
                class="cls-2"
                d="M99.92,197.22c-.56,12.16,95.82-8.82,110.61-8.5V176C195.54,180.07,98.21,185.07,99.92,197.22Z"
              />
              <path
                class="cls-2"
                d="M289.41,77.59c-32.65,5.62-66.5,8.24-99.7,11.81.28,3.75.54,7.16.83,11C199.6,97.58,319.63,96.54,289.41,77.59Z"
              />
              <path
                class="cls-2"
                d="M276.59,167.31c.34,4.21.65,8.06,1,12.46,7.83-3,27.23,1,27.09-10C300.8,159.06,285.06,167.86,276.59,167.31Z"
              />
              <path
                class="cls-2"
                d="M98.06,273.77c-3.32.21-5.11,1.92-5.28,4.43.7,10.05,23.46,5.1,31.39,7.38-.51-4.47-3.19-7.67-4.86-11.74C113,271.61,104.9,274,98.06,273.77Z"
              />
              <path
                class="cls-2"
                d="M121.69,110.35c-.34-2.91-.65-5.57-1-8.58C88.89,105.58,89.66,116.7,121.69,110.35Z"
              />
            </svg>
          </div>
          <h2 class="mb-2 mt-6 text-lg font-semibold">Adjust To Suit</h2>
          <div class="mx-auto">
            Select the exact flavours they like for their own monthly order.
          </div>
        </div>
        <div
          class="z-10 flex w-full flex-col justify-start text-center sm:w-64"
        >
          <div
            class="color-transition relative mx-auto flex h-32 w-32 items-center justify-center rounded-full sm:h-40 sm:w-40"
            :class="[fillCol, bgCol]"
          >
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 370.22 370.22"
              class="h-full w-full"
            >
              <path
                class="svgFill"
                d="M335.43,165.56a43.91,43.91,0,0,1-1.36-6.15,46,46,0,0,0-9.43-24.26c-3.63-6.8-7.83-13.12-14.55-17.32-.63-.57-1.28-1.11-1.88-1.7-3.67-3.63-7-7.64-11.71-10h0c-.16-.16-.3-.41-.49-.47-7.83-2.5-14.66-7.48-22.83-9.11-1.4-.27-2.07-.81-2.06-2.41a6.74,6.74,0,0,0-1.78-4.44h0a11.66,11.66,0,0,0-7.84-5c-6.57-1.15-13.16-.51-19.72.1a40.16,40.16,0,0,0-9.07,1.61c-.69.11-1.38.2-2.06.34-7.37,1.52-14.61,3.64-22.13,4.46-2.91.31-5.8.84-8.69,1.27h0A94.45,94.45,0,0,0,178.58,96a1.61,1.61,0,0,0-.74.2c-8.14,1.93-16.29,3.83-24.33,6.16a86.33,86.33,0,0,0-21.11,10.27h0c-3.28,2-6.51,4-9.83,5.84-13.72,7.69-26.5,16.6-37.22,28.26-5,5.44-10.68,10.25-15.29,16.08a105.73,105.73,0,0,0-13.18,20.3,161.68,161.68,0,0,0-7.47,15.32,96.3,96.3,0,0,0-6.57,19.69c-.44.31-.41.79-.48,1.25-.84,5.44-1.63,10.87-2,16.37-.48,8-1.16,16,.94,23.86,3.4,12.76,9.9,24.11,16.49,35.41h0A120.68,120.68,0,0,0,79.12,319.4a56.15,56.15,0,0,0,18.72,11.21c8.09,2.84,16.34,5.15,24,9.16,4.18,2.18,8.54,4.19,13.3,5a1.29,1.29,0,0,0,.45.16c3.67,1.37,7.55,1.65,11.37,2.1,7.09.82,14.16,1.64,21.16,3.11,8,1.68,16,1.8,23.91-.65a99,99,0,0,0,14.07-2.81h0a34.29,34.29,0,0,0,9.28-2.9h0a62.77,62.77,0,0,0,10.77-6.09l2.82-1.89,2.82-1.86h0l7.4-4.54.16-.1c8.08-4.11,15.36-9.24,21.09-16.36,0,0,.05,0,.07-.08a55.68,55.68,0,0,0,8.38-11.17h0q4.46-6.6,8.9-13.19c2.2-3.09,4.44-6.15,6.58-9.27.73-1.06,1.76-2,1.79-3.45l.09-.4c1.83-1.1,2.46-3.14,3.68-4.72h0l.95-1.38h0l1.41-1.88h0l3.27-4.7h0c4.75-6.41,7.75-14,13.58-19.7,1.82-2.2,3.79-4.31,5.44-6.64,2.4-3.39,5.13-6.62,6.27-10.75h0c2.77-5.66,6.85-10.58,9.4-16.36,1.61-1.67,1.88-3.9,2.35-6,1.48-6.6,1.64-13.41,2.87-20.06C336.56,177.29,336.91,171.43,335.43,165.56Z"
              />
              <path
                class="cls-2"
                d="M291,120.93c6.92-1.49,6.34-11.84-.78-12.61-1.89-.29-2.25-1.14-2.33-2.85-.57-16-1.58-32.07-3.32-48,.75-12.65-26.85-4.35-35.11-5.11-4.26.15-2.64-6.75-4.52-9.46-.65-1.92-2-3.35-4.27-3.06-5.7,1.49-2.79,9-3.53,13.42l-29.91,2.4c-.75-12.88-8.46-16-9.24-1.38.56,4.46-19.73,2.89-23.85,4.08.46-15-6.07-15.93-8.86-1.29-.93,4.61-27.5,3.65-33.06,4.58-1.28-4.45.38-14.16-4-16.25-5.91,2.15-3.22,12.08-4.43,17.23l-23.65,2.06c-1.63-4.34.37-14.86-5.39-15.51-4.17.41-3.24,5.59-3.72,8.61,1,8.8-3,10.23-11,8.69-1.86-4.15-5.61-1.34-6.15,1.85-2.34,73.19,2,147.06,8.49,219.83-.35,4.13.74,5.74,4.83,6.11,67.52-3.18,135.3-9,202.64-14.84,4.58-1.48,5.84-6.36,2.76-10C293.12,221.49,285.31,166.83,291,120.93Zm-207.61.54c-.78-14.66-1.56-29.56-2.37-44.94,8.75.38,10.2-2.55,12.72,7.17,6,7.93,11.54,0,8.16-7.5,37.09-4.1,13.54-.44,31.38,8,.54-4.18-1.62-8.53,1.83-10.52,9.47-.82,19-1.49,28.57-2.19,1.24,4.29,2.34,11.57,8.47,10.37,1.25-3.95-.52-9.52,3.3-11.19,7.14-.61,14.28-1.16,21.43-1.56,4.57,2.21,3.9,9.68,9.46,11.44,3-2.61-2.26-11.9,3.15-12.5q13.69-.95,27.39-2.12c4.95.62,4.58,8.92,9.12,10.8,2.41-3.25-.29-9.2,3.14-11.66,8.82-.86,17.67-1.56,26.64-2.3v45.86Zm9,162c-3.28-49.54-7.89-99.28-8.47-148.88L276,122.56c1.42,48.47,2.83,96.66,4.24,144.9Z"
              />
              <path
                class="cls-2"
                d="M250.9,191.51l-.48.86c38.83,34.49-35.44,51.62-10.59,9.25,2.32-3.88,6.38-3.63,10.23-4.66l.15-.79c-18.87-14.3-35,33.67-10.55,37.46C271.84,244.16,284.27,196,250.9,191.51Z"
              />
              <path
                class="cls-2"
                d="M128.88,224.92c0-7.67-11.75-8.59-11.71-.53C116.9,231.83,128.93,232.19,128.88,224.92Z"
              />
              <path
                class="cls-2"
                d="M239.83,180.2c-.2,7.51,11.67,8.11,11.5.23C251.55,172.67,239.85,172.53,239.83,180.2Z"
              />
              <path
                class="cls-2"
                d="M247.38,218.53c7.38.3,7.85-11.28.35-11.35C240.35,206.86,239.93,218.52,247.38,218.53Z"
              />
              <path
                class="cls-2"
                d="M210.71,185.18c0-7.12-11.05-6.94-10.78.18C199.88,192.49,211,192.28,210.71,185.18Z"
              />
              <path
                class="cls-2"
                d="M209.14,242.28c-7-.2-7.07,11.06-.07,11C216.08,253.52,216.12,242.17,209.14,242.28Z"
              />
              <path
                class="cls-2"
                d="M126.43,254.34c-7.05-.08-6.73,11,.34,10.77C135.14,264.53,133,255.13,126.43,254.34Z"
              />
              <path
                class="cls-2"
                d="M207.53,211.68c-7.27-.22-7.29,11,0,10.73C214.62,222.48,214.65,211.58,207.53,211.68Z"
              />
              <path
                class="cls-2"
                d="M168.11,215.71c-6.65-.38-7.84,10.72-1,11C173.89,226.77,175.83,216,168.11,215.71Z"
              />
              <path
                class="cls-2"
                d="M167.19,251.59c-.26,7.13,10.36,8,10.36.53C177.8,245.14,167.21,244.37,167.19,251.59Z"
              />
              <path
                class="cls-2"
                d="M242.5,155.18c6.54,0,7.06-10.72.22-10.75C235.93,144.2,235.63,155.36,242.5,155.18Z"
              />
              <path
                class="cls-2"
                d="M209.11,153.27c.22-6.64-10.39-6.94-10.42-.22C198.31,160,209.13,160.1,209.11,153.27Z"
              />
              <path
                class="cls-2"
                d="M171.62,187.73c-1.17-7.15-9.59-7.9-9.92.52C161.86,194.91,171.87,194.12,171.62,187.73Z"
              />
              <path
                class="cls-2"
                d="M129.05,190c0-6.44-10.18-6.34-10.06.08C118.83,196.61,129.35,196.61,129.05,190Z"
              />
              <path
                class="cls-2"
                d="M250.92,240.92c-4.86,0-4.71,7.94.26,7.47C256.05,248.35,255.62,240.72,250.92,240.92Z"
              />
              <path
                class="cls-2"
                d="M113.77,96c-25.27,2.36-28,13.43.09,9.93l0,.19c10.12-.06,20.23-1.17,30.34-1.72,4.81-.78,13.15.9,13.83-5.83C160.24,86.75,121.5,96.69,113.77,96Z"
              />
            </svg>
          </div>
          <h2 class="mb-2 mt-6 text-lg font-semibold">Relax</h2>
          <div class="mx-auto">
            Receive food they’ll love every month and never run out again!
          </div>
        </div>
      </div>
      <div class="absolute top-0 z-0 hidden w-full px-32 py-20 sm:block">
        <div class="color-transition h-1 bg-black"></div>
      </div>
    </div>

    <div
      class="mx-8 flex flex-col items-center justify-center space-y-8 pt-16 sm:pb-12 sm:pt-24"
    >
      <div class="text-center text-lg font-semibold">
        Easily Update your choices,<br />
        Pause or Cancel anytime!
      </div>
      <action-ctas
        class="w-full sm:inline-flex sm:justify-center"
        section="three steps"
      />
    </div>
    <div class="w-full sm:-mt-32" :class="[fillCol, bgCol]">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="currentColor"
          fill-opacity="1"
          d="M0,160L48,176C96,192,192,224,288,234.7C384,245,480,235,576,229.3C672,224,768,224,864,218.7C960,213,1056,203,1152,170.7C1248,139,1344,85,1392,58.7L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
import ActionCtas from "@/components/ActionCtas";
export default {
  props: {
    color: {
      type: String,
    },
  },
  components: { ActionCtas },
  computed: {
    fillCol() {
      if (this.color) {
        return this.color.replace("bg", "text").replace("400", "200");
      }
      return `text-teal-200`;
    },
    bgCol() {
      if (this.color) {
        return this.color.replace("400", "100");
      }
      return `text-teal-100`;
    },
    fillColBg() {
      if (this.color) {
        return this.color.replace("bg", "text").replace("400", "100");
      }
      return `text-teal-100`;
    },
  },
};
</script>

<style lang="scss">
.svgFill {
  fill: currentColor;
}
.cls-2 {
  fill: #000000;
}
</style>
