<template>
  <div>
    <div
      class="py-16 sm:py-12 pb-0 px-4 -mt-2 color-transition"
      :class="`bg-${coreColor}-200`"
    >
      <div class="container mx-auto flex justify-center flex-col">
        <div class="grid gap-6 grid-cols-4">
          <div class="col-span-4 sm:col-span-2 sm:col-start-2">
            <h3
              class="text-2xl xl:text-3xl text-center has-blue font-semibold mb-8 sm:-mt-0 z-30 relative"
            >
              Our Pricing
            </h3>
            <div class="space-y-4">
              <div class="pb-4 text-center">
                Here's pricing for our popular pouches.
              </div>
              <div
                class="col-span-4 sm:col-span-2 py-4 space-y-4 flex flex-col items-end order-first sm:order-last"
              >
                <div
                  class="grid grid-cols-4 gap-6 relative px-0 grid-6 sm:px-8 sm:pb-6"
                >
                  <div
                    class="rounded-lg w-full sm:w-96 bg-white h-24 p-8 py-4 grid grid-cols-3 gap-4 col-span-4 sm:col-span-3"
                    :class="`shadow-${cat.shadow} sm:col-start-${cat.start}`"
                    v-for="(cat, i) in catExamples"
                    :key="cat.name"
                  >
                    <div class="col-span-2">
                      <div class="text-xl py-1">
                        <span class="font-semibold flex space-x-1">
                          <div class="font-semibold">
                            {{ cat.products[0].amount }}
                          </div>
                          <div class="text-gray-600 font-normal">
                            {{ cat.products[0].type }}
                          </div></span
                        >
                      </div>
                      <div
                        class="flex space-x-1 text-gray-600 text-xs"
                        :key="`${cat.name}-product-${i}`"
                      >
                        incl. delivery
                      </div>
                    </div>
                    <div class="col-span-1 text-right">
                      <div class="font-semibold text-2xl">{{ cat.value }}</div>
                      <div class="text-gray-600 text-xs pt-1">/ per month</div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="text-center flex items-center justify-center space-x-3"
              >
                <div class="w-10 h-1" :class="`bg-${coreColor}-400`"></div>
                <div>Or</div>
                <div class="w-10 h-1" :class="`bg-${coreColor}-400`"></div>
              </div>
              <div class="py-20 pt-3 text-center sm:px-24">
                <strong>Choose any combination</strong> of our different types
                of wet or dry food and the price adjusts accordingly.
                <div class="font-sans mt-4">
                  <router-link :to="{ name: 'pricing' }" class="underline"
                    >Try our pricing calculator
                    <font-awesome-icon
                      :icon="['far', 'arrow-right-long']"
                      class="text-gray-800"
                  /></router-link>
                </div>
              </div>

              <div class="text-center">
                Our Taster Box is full of our most popular food so they can
                enjoy finding their favourites...
              </div>
              <div
                class="flex sm:space-x-6 items-center py-8 pt-3 flex-col space-y-6"
              >
                <action-ctas class="w-full sm:w-auto" section="pricing" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="-mt-2 relative flex w-full justify-center color-transition"
      :class="`text-${coreColor}-200`"
    >
      <div class="w-full bg-gray-100">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 1440 200.7"
          xml:space="preserve"
        >
          <path
            fill="currentColor"
            d="M0,32l48,37.3C96,107,192,181,288,197.3C384,213,480,171,576,144s192-37,288-42.7c96-5.3,192-5.3,288-16
	c96-10.3,192-32.3,240-42.6l48-10.7V0h-48c-48,0-144,0-240,0S960,0,864,0S672,0,576,0S384,0,288,0S96,0,48,0H0V32z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { split } from 'lodash/fp';
import ActionCtas from '@/components/ActionCtas';
export default {
  props: ['color'],
  components: { ActionCtas },
  data() {
    return {
      catExamples: [
        {
          name: 'Simba',
          value: '£24.99',
          shadow: '2xl',
          start: 1,
          products: [
            {
              amount: 30,
              type: 'Pouches'
            }
          ]
        },
        {
          name: 'Mog',
          value: '£44.99',
          shadow: 'xl',
          start: 2,
          products: [
            {
              amount: 60,
              type: 'Pouches'
            }
          ]
        },

        {
          name: 'Nala',
          value: '£64.99',
          shadow: 'lg',
          start: 1,
          products: [
            {
              amount: 90,
              type: 'Pouches'
            }
          ]
        }
      ]
    };
  },
  computed: {
    coreColor() {
      return split('-', this.color)[1];
    }
  }
};
</script>

<style></style>
