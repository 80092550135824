<template>
  <div>
    <div
      class="relative flex flex-col items-center overflow-hidden sm:flex-row sm:p-4 sm:pb-8"
    >
      <div class="absolute left-0 right-0 top-0 z-10">
        <div class="h-72" :class="color"></div>
        <div class="h-80" :class="color"></div>
        <div :class="textColor" class="relative z-20 w-full">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="currentColor"
              fill-opacity="1"
              d="M0,32L48,69.3C96,107,192,181,288,197.3C384,213,480,171,576,144C672,117,768,107,864,101.3C960,96,1056,96,1152,85.3C1248,75,1344,53,1392,42.7L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
            ></path>
          </svg>
        </div>
      </div>
      <div class="container z-20 mx-auto bg-teal-400 sm:bg-transparent">
        <div class="items flex w-full flex-col">
          <slot name="trustpilot"></slot>
          <h1
            class="has-blue px-6 text-center font-merri text-3xl font-bold sm:text-5xl"
            v-if="!hideHeading"
            :class="[
              hasSubline ? 'mb-2 sm:mb-2' : 'mb-4 sm:mb-16',
              hasTrustpilot ? 'sm:mt-6' : 'sm:mt-12'
            ]"
          >
            {{ activeHeading | unencodeURL }}
          </h1>
          <slot name="subline"></slot>
          <div class="flex flex-row sm:flex-col">
            <div
              class="max-content -ml-40 flex w-full flex-col space-y-6 py-6 pt-4 sm:mx-auto sm:ml-auto sm:w-4/5 sm:flex-row sm:space-y-0"
            >
              <div
                class="ml-56 w-48 flex-1 rotate-2 transform items-center justify-center space-y-2 text-center sm:-ml-0 sm:ml-0 sm:w-auto sm:-rotate-2 sm:space-y-4"
              >
                <div
                  class="relative flex inline-flex transform justify-center rounded bg-pink-200 p-2 px-3 pt-0 font-bold sm:text-2xl"
                >
                  <div
                    class="absolute right-0 top-0 -mr-1 mt-2 h-6 w-6 rotate-45 transform rounded bg-pink-200 sm:left-auto sm:-ml-0 sm:-mt-2 sm:hidden"
                  ></div>
                  <div class="relative border-b-4 border-pink-400">
                    Huge Choice...
                  </div>
                </div>
                <div class="has-blue text-sm sm:text-lg">
                  ...of extremely tasty,<br />
                  Wet & Dry food
                </div>
              </div>

              <div
                class="-mr-24 ml-32 flex-1 -rotate-2 transform items-center justify-center space-y-2 text-center sm:ml-0 sm:mr-0 sm:rotate-2 sm:space-y-4"
              >
                <div
                  class="relative inline-flex transform justify-center rounded bg-blue-200 p-2 px-3 pt-0 font-bold sm:text-2xl"
                >
                  <div
                    class="absolute right-0 top-0 -mr-1 mt-2 h-6 w-6 rotate-45 transform rounded bg-blue-200 sm:left-auto sm:-ml-0 sm:-mt-2 sm:hidden"
                  ></div>
                  <div class="relative border-b-4 border-blue-400">
                    Pick & Mix...
                  </div>
                </div>
                <div class="has-blue text-sm sm:text-lg">
                  ...to create their perfect<br />
                  monthly order
                </div>
              </div>
              <div
                class="ml-40 flex-1 rotate-2 transform items-center justify-center space-y-2 text-center sm:ml-0 sm:space-y-4"
              >
                <div
                  class="relative inline-flex transform justify-center rounded bg-indigo-200 p-2 px-3 pt-0 font-bold sm:text-2xl"
                >
                  <div
                    class="absolute right-0 top-0 -mr-1 mt-2 h-6 w-6 rotate-45 transform rounded bg-indigo-200 sm:left-auto sm:-ml-0 sm:-mt-2 sm:hidden"
                  ></div>
                  <div class="relative border-b-4 border-indigo-400">
                    Delivered Monthly...
                  </div>
                </div>
                <div class="has-blue text-sm sm:text-lg">
                  ...so you never run out again
                </div>
              </div>
            </div>
            <div
              class="my-4 -mr-32 mt-0 mt-4 flex w-full flex-none pl-4 sm:mx-auto sm:w-3/5 sm:pl-0"
            >
              <img
                src="/images/hp_img.webp"
                class="flex-none object-contain"
                alt="The Cool Cat Club Food benefits"
              />
            </div>
          </div>

          <div class="flex w-full justify-center pt-4">
            <action-ctas
              class="w-full sm:flex sm:justify-center"
              section="hero"
            />
          </div>

          <trustbox class="mt-6 w-full" section="hero" />
        </div>
      </div>
    </div>

    <div class="relative w-full justify-center pb-12 sm:pb-24">
      <div :class="textColor" class="relative z-20 w-full sm:hidden">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="currentColor"
            fill-opacity="1"
            d="M0,32L48,69.3C96,107,192,181,288,197.3C384,213,480,171,576,144C672,117,768,107,864,101.3C960,96,1056,96,1152,85.3C1248,75,1344,53,1392,42.7L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
          ></path>
        </svg>
      </div>
      <div
        class="relative flex w-full flex-col justify-center py-6 text-left text-black sm:top-0 sm:flex-row sm:flex-row-reverse sm:py-24"
      >
        <div class="container flex sm:flex-row-reverse">
          <div
            class="flex flex-col flex-col-reverse sm:flex-row sm:flex-row-reverse"
          >
            <div class="flex flex-col sm:self-center sm:px-24">
              <div
                class="py-8 pb-4 text-center text-xl font-semibold sm:text-2xl"
              >
                <div
                  class="text-center text-xl sm:px-0 sm:text-left sm:text-2xl xl:text-2xl"
                >
                  Huge Choice of Premium Food.<br class="" />
                  All in one place.
                </div>
              </div>
              <div class="px-6 text-center sm:px-0 sm:text-left">
                Try first then Pick & Mix to create their perfect order.
                <div
                  class="inline-flex text-center font-sans sm:flex sm:text-left"
                  @click="
                    sendEvent({
                      event: 'View our food',
                      properties: {
                        location: $route.name,
                        type: 'EVT'
                      }
                    })
                  "
                >
                  <router-link :to="{ name: 'the food' }" class="underline"
                    >View all our food
                    <font-awesome-icon
                      :icon="['far', 'arrow-right-long']"
                      class="text-gray-800"
                  /></router-link>
                </div>
              </div>

              <div class="flex w-full justify-start pt-8">
                <action-ctas
                  class="w-full sm:flex sm:justify-center"
                  section="hero"
                />
              </div>
            </div>
            <div class="z-50 w-full flex-none sm:-mt-0 sm:w-1/2">
              <div class="flex flex-wrap items-center justify-center">
                <div
                  class="w-1/3 pb-6 sm:p-4"
                  :key="`food-type-${index}`"
                  v-for="(food, index) in foodTypes"
                >
                  <div
                    class="flex flex-col items-center justify-center p-1 sm:p-0"
                  >
                    <div class="rounded-full">
                      <img
                        :src="food.image"
                        :alt="`Image of ${food.name} food `"
                      />
                    </div>

                    <div class="text-xl font-semibold">{{ food.name }}</div>
                    <div class="text-sm text-gray-600">
                      <span class="font-semibold text-gray-800">{{
                        food.flavours
                      }}</span>
                      flavours
                    </div>
                  </div>
                </div>
              </div>
              <!-- <img
            src="/images/food-w-benefits.webp"
            alt="The Cool Cat Club Food benefits"
            class="mt-6 "
          /> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <three-steps :color="color" />

    <pricing-section :color="color" class="relative -mt-2" />
  </div>
</template>

<script>
import ThreeSteps from '@/components/ThreeSteps';
import Trustbox from '@/components/Trustbox';

import PricingSection from '@/components/PricingSection';
import ActionCtas from '@/components/ActionCtas';
import { mapState, mapGetters, mapActions } from 'vuex';
import { includes } from 'lodash/fp';
import utils from '@/utils';

export default {
  name: 'BaseHomepage',
  metaInfo() {
    const colorHex = this.colorHex;
    return {
      title:
        'The Complete Cat Food Solution. Pick & Mix for their perfect cat food subscription box',
      meta: [
        {
          name: 'description',
          content:
            'Huge choice of Wet & Dry Food, all in one easy place. Try first then Pick & Mix for the perfect order. Try Today!'
        },
        { name: 'theme-color', content: `${colorHex}` }
      ]
    };
  },
  props: {
    color: { type: String, default: 'bg-teal-400' },
    heading: { type: String },
    params: { type: Object, default: () => {} },
    hideHeading: { type: Boolean, default: false }
  },
  components: {
    ActionCtas,
    ThreeSteps,
    Trustbox,

    PricingSection
  },
  data() {
    return {
      foodTypes: [
        {
          name: 'Fillet',
          type: 'wet_can'
        },
        {
          name: 'Pate',
          type: 'wet_tray'
        },
        {
          name: 'Flakes',
          type: 'wet_pouch'
        },
        {
          name: 'Chunks',
          type: 'wet_chunk'
        },
        {
          name: 'Dry',
          type: 'dry'
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      products: 'productGroupsGetter'
    }),
    textColor() {
      return this.color.replace('bg', 'text');
    },

    colorHex() {
      if (includes('teal', this.color)) {
        return '#4fd1c5';
      }
      if (includes('blue', this.color)) {
        return '#63b3ed';
      }
      if (includes('indigo', this.color)) {
        return '#7f9cf5';
      }

      return null;
    },
    borderColor() {
      return this.color.replace('bg', 'border');
    },
    textColorDark() {
      return this.color.replace('bg', 'text').replace('400', '700');
    },
    bgColorLight() {
      return this.color.replace('400', '300');
    },
    ...mapState({
      ctaText: 'ctaText',
      ctaTextTaster: 'ctaTextTaster'
    }),
    ...mapGetters({
      discountAmount: 'getDiscountAmount'
    }),
    activeHeading() {
      return this.heading || 'The Complete Cat Food Solution';
    },
    hasTrustpilot() {
      return this.params?.trustpilot === 'true';
    },
    hasSubline() {
      return this.params?.subline;
    }
  },
  methods: {
    setTaster(label, isTaster) {
      this.$store.commit('set_as_taster', isTaster);
    },
    ...mapActions({
      sendEvent: 'sendMpEvent'
    }),
    getImageUrl(url, config) {
      return utils.transformImage(url, config);
    }
  },
  watch: {
    products: {
      handler(val) {
        this.foodTypes = this.foodTypes.map((food) => {
          const product = // find the group that matches the type
            val.find((product) => product.key === food.type) || {};
          console.log(product);
          return {
            ...food,
            image: utils.transformImage(
              product.items[0].image.food,
              'ar-1_1,w-400'
            ),
            flavours: product.items.length
          };
        });
      },
      immediate: false
    }
  },
  mounted() {
    // for each of the tood types, get the image and the number of flavours
  }
};
</script>

<style lang="scss" scoped>
.has-blue {
  color: #0b315e;
}
.outline-btn {
  border-color: rgba(11, 49, 94, 0.8);
  &:hover {
    color: #0b315e;
    background: rgba(11, 49, 94, 0.1);
  }
}
.mini-rotate {
  transform: rotate(-3deg);
}
.svg-fill {
  @apply fill-current;
}

.right-after-signpost-pink {
  &:after {
    /*  our pseudo-element */
    content: ''; /* required */
    position: absolute; /* takes the 'cap' out off flow */
    top: 0; /* stick it to top edge of the sign */
    left: 99%; /* push it way overto the right*/
    height: 0; /* we're doing this with borders remember */
    width: 0;
    border-width: 25px;
    border-style: solid;
    @apply border-pink-200;
    /* now we make some of theborders disappear*/
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent;
  }
}
.right-after-signpost-blue {
  &:after {
    /*  our pseudo-element */
    content: ''; /* required */
    position: absolute; /* takes the 'cap' out off flow */
    top: 0; /* stick it to top edge of the sign */
    left: 99%; /* push it way overto the right*/
    height: 0; /* we're doing this with borders remember */
    width: 0;
    border-width: 25px;
    border-style: solid;
    @apply border-blue-200;
    /* now we make some of theborders disappear*/
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent;
  }
}
.left-after-signpost-indigo {
  &:after {
    /*  our pseudo-element */
    content: ''; /* required */
    position: absolute; /* takes the 'cap' out off flow */
    top: 0; /* stick it to top edge of the sign */
    right: 99%; /* push it way overto the right*/
    height: 0; /* we're doing this with borders remember */
    width: 0;
    border-width: 25px;
    border-style: solid;
    @apply border-indigo-200;
    /* now we make some of theborders disappear*/
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}

.scroller {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroller linear;
  animation-timeline: scroll(inline);
}

@keyframes scroller {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}
</style>
