<template>
  <div>
    <div class="grid items-center text-center">
      <!-- <div class=" flex items-center space-x-1 justify-center mb-4">
        <img
          v-for="(star, index) in 5"
          :key="`star-${index}`"
          class="w-8 h-8"
          src="/images/tp-star.svg"
        />
      </div> -->
      <div
        class="trustpilot-widget"
        data-locale="en-GB"
        data-template-id="5419b6a8b0d04a076446a9ad"
        data-businessunit-id="5e8c9ae1b12e1e00015e0b79"
        data-style-height="24px"
        data-style-width="100%"
        data-theme="light"
      >
        <a
          href="https://uk.trustpilot.com/review/thecoolcatclub.com"
          target="_blank"
          rel="noopener"
          class="text-sm has-blue"
          ><span class="font-semibold">500+</span> reviews on Trustpilot</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {}
};
</script>

<style lang="scss" scoped>
.has-blue {
  color: #0b315e;
}
</style>
